/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../../styles/postlogin/services.css";
import ServiceCard from "../../../../components/Cards/ServicesAll";
import { getRequest } from "../../../../interceptor/AxiosConfig";
import { servicesListEndpoint } from "../../../../apis/postlogin/PostloginApis";
import * as routePath from "../../../../routes";
import { useLocation, useNavigate } from "react-router-dom";
// import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";

import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis";
import Header from "../../../../components/Header/index";
import { LocalStorage } from "../../../../utilsfunction/Encrypt";
import Footer from "../../../../components/Footer/Footer";

const Services = () => {
  const base_url = process.env.REACT_APP_API_URL + "uploads/";
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const categoryValue = location.state?.categoryValue;

  const [loginType, setLoginType] = useState("")
  const [name, setName] = useState(null);
  const [tempName, setTempName] = useState(name);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);

  const [tempPhone, setTempPhone] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [activeLink, setActiveLink] = useState(categoryValue ? categoryValue : null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);

  const getServices = async () => {
    setLoader(true);
    await getRequest(`${servicesListEndpoint}`)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          const fetchedCategories = response.data.data;
          setCategories(fetchedCategories);

          // Set the first category as active and its services by default
          if (fetchedCategories.length > 0) {
            const initialCategory = fetchedCategories[0];
            setActiveLink(categoryValue ? categoryValue : initialCategory.name);
            setServices(initialCategory.services);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        // if (err.response) {
        //   setErrorMessage(ErrorMessageHandling(err));
        // }
        setErrorMessage(null);
      });
  };

  const getCustomerProfile = async () => {
    await getRequest(`${apiEndpoint.profileDataAPIEndpoint}`)
      .then((res) => {
        if (res.status === 200) {

          const phoneNumber = res.data.data.phone_number;

          setEmail(res.data.data.email);
          setName(res.data.data.full_name);
          setPhone(res.data.data.phone_number);
          setProfilePicture(res.data.data.profile);
          setIsSubscribed(res.data.subscriptionStatus)
          setLoginType(res.data.data.login_type)

          if (!phoneNumber) {
            setShowPhoneDialog(true); // Show the dialog if phone number is null
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          // setErrorMessage(ErrorMessageHandling(err));
          // setErrorMessage("")
        }
      });
  };

  const PhoneDialog = ({ onClose }) => {
    const handleOkClick = () => {
      navigate('/user/user-profile'); // Navigate to the profile page
      onClose(); // Close the dialog
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-lg w-full transform transition-transform duration-300 ease-in-out scale-95 hover:scale-100">
          <h2 className="text-2xl font-bold text-gray-800">Please Add Your Phone Number</h2>
          <p className="mt-2 text-gray-600">You need to provide your phone number to continue.</p>
          <div className="mt-6 flex justify-end">
            <button
              className="px-6 py-3 bg-green-500 text-white rounded-lg shadow hover:bg-green-600 transition duration-200"
              onClick={handleOkClick}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };



  useEffect(() => {
    getCustomerProfile();
    getServices();
  }, []);

  useEffect(() => {
    handleLinkClick(activeLink)
  }, [activeLink, categories])

  const handleLinkClick = (categoryName) => {
    setActiveLink(categoryName);
    const selectedCategory = categories.find(
      (category) => category.name === categoryName
    );

    // Set the services for the selected category
    if (selectedCategory) {
      setServices(selectedCategory.services);
    }
  };

  const handleBookingNavigation = (id) => {
        console.log('step 11',id);
    navigate(
      `/${routePath.CustomerLayoutPath}/${routePath.servicesBookingPath}/${id}`
    );
  };

  // const [isOpen, setIsOpen] = useState(false);
  // const dropdownRef = useRef(null);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleClickOutside = (e) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
  //     setIsOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  const localstorage = new LocalStorage();
  const auth = localstorage.getItem("1");
  return (
    <div>
      {!auth && <Header />}

      {showPhoneDialog && (
        <PhoneDialog onClose={() => setShowPhoneDialog(false)} />
      )}

      {errorMessage && <Error errorMessage={errorMessage} />}
      <section className="container mx-auto min-h-screen services">
        {loader ? (
          <div className="m-auto">Loading...</div>
        ) : (
          <>
            <div className="p-4 ps-0">
              <div className="heading_first">
                All <span className="heading_second">Services</span>
              </div>
            </div>
            <div className="flex flex-row justify-evenly services_topbar items-center">
              {categories?.map((category, index) => (
                <div
                  key={index}
                  className={`cursor-pointer ${activeLink === category?.name ? "activeHead" : "head_link"}`}
                  onClick={() => handleLinkClick(category?.name)}
                >
                  <span>{category?.name}</span>
                </div>
              ))}
            </div>

            <div className="services_card mt-4 p-4 ps-0 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-center justify-between">
              {services?.map((service, index) => (
                <ServiceCard
                  key={index}
                  ServiceImg={`${base_url}${service.image}`}
                  ServiceHead={service.name}
                  ServiceDescription={service.description}
                  ButtonTitle={"Book Now"}
                  servicePrice={service.price}
                  bookingServices={() => handleBookingNavigation(service.id)}
                />
              ))}
            </div>
          </>
        )}
      </section>
      {!auth && <Footer />}
    </div>
  );
};

export default Services;
